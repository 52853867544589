import {Aktualita} from "../../src/interfaces/interfaces";

const aktuality : Aktualita[] = [
        {
                title: 'Historický úspěch našich gymnastek - družstvo juniorek ESG skončilo na Mistrovství světa v Estonsku na 11. místě!  ',
                PDFtitle: 'výsledky MS',
                PDF: 'MS ESG 2024.pdf'
        },
        
     

]

export const aktualityImage  = "";         // jmeno souboru i s příponou, který vložíš do /obrazky/akuality

export default aktuality
